var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height row excel-upload-cover" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
        [
          _c("q-uploader", {
            ref: "excel-upload",
            staticClass: "excel-upload",
            style: _vm.maxheight,
            attrs: {
              flat: "",
              bordered: "",
              "with-credentials": "",
              capture: "environment",
              "field-name": "file",
              method: "POST",
              label: _vm.label,
              "auto-upload": false,
              "hide-upload-btn": true,
              multiple: false,
              accept: "xlsx",
              "max-file-size": 10000000,
              "max-total-size": 10000000,
              "max-files": 1,
              "no-thumbnails": false,
              disable: !_vm.editable,
            },
            on: { rejected: _vm.rejected, added: _vm.added },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row no-wrap items-center q-pa-none q-gutter-xs",
                      },
                      [
                        scope.isUploading
                          ? _c("q-spinner", {
                              staticClass: "q-uploader__spinner",
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "q-uploader__title inline-block" },
                            [_vm._v(_vm._s(_vm.label))]
                          ),
                          _vm._v("   "),
                          _c(
                            "div",
                            {
                              staticClass: "q-uploader__subtitle inline-block",
                            },
                            [
                              _vm._v(
                                _vm._s(scope.uploadSizeLabel) +
                                  " / " +
                                  _vm._s(scope.uploadProgressLabel)
                              ),
                            ]
                          ),
                        ]),
                        _vm.editable
                          ? _c("q-btn", {
                              staticClass: "excel-upload-btn",
                              attrs: {
                                icon: "file_download",
                                dense: "",
                                outline: "",
                                size: "sm",
                                label: "양식 다운로드",
                              },
                              on: { click: _vm.downTemplate },
                            })
                          : _vm._e(),
                        scope.queuedFiles.length > 0 && _vm.editable
                          ? _c("q-btn", {
                              staticClass: "excel-upload-btn",
                              attrs: {
                                icon: "delete",
                                dense: "",
                                outline: "",
                                size: "sm",
                                label: "파일 삭제",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteFile(scope)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "list",
                fn: function (scope) {
                  return [
                    scope.files && scope.files.length > 0
                      ? [
                          _c(
                            "q-list",
                            { attrs: { separator: "" } },
                            _vm._l(scope.files, function (file) {
                              return _c(
                                "q-item",
                                { key: file.name },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c(
                                        "q-item-label",
                                        { staticClass: "full-width ellipsis" },
                                        [_vm._v(" " + _vm._s(file.name) + " ")]
                                      ),
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _vm._v(
                                            " Status: " +
                                              _vm._s(file.__status) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(file.__sizeLabel) +
                                              " / " +
                                              _vm._s(file.__progressLabel) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  file.__img
                                    ? _c(
                                        "q-item-section",
                                        {
                                          staticClass: "gt-xs",
                                          attrs: { thumbnail: "" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: { src: file.__img.src },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            { staticClass: "empty_dscription" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "q-chip",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { square: "", icon: "info" },
                                    },
                                    [_vm._v(" Drag or Click ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "q-pl-md",
                                  staticStyle: { "text-align": "left" },
                                },
                                [
                                  _c("span", { staticClass: "text-positive" }, [
                                    _vm._v("업로드 가능 확장자"),
                                  ]),
                                  _vm._v(" : xlsx "),
                                  _c("br"),
                                  _c("span", { staticClass: "text-positive" }, [
                                    _vm._v("업로드 가능 파일 수"),
                                  ]),
                                  _vm._v(" : 1 "),
                                  _c("br"),
                                  _c("span", { staticClass: "text-positive" }, [
                                    _vm._v("허용 파일 크기"),
                                  ]),
                                  _vm._v(" : 10MB "),
                                ]
                              ),
                              _c("q-uploader-add-trigger"),
                            ],
                            1
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm excel-upload-status-card",
              attrs: { title: "업로드 현황", height: "150px" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.convertEnter(_vm.message)),
                    },
                  }),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-tab", {
            attrs: {
              type: "tabcard",
              align: "left",
              tabItems: _vm.tabItems,
              inlineLabel: true,
              height: "430px",
            },
            on: {
              "update:tabItems": function ($event) {
                _vm.tabItems = $event
              },
              "update:tab-items": function ($event) {
                _vm.tabItems = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (tab) {
                  return [
                    _c(
                      "c-table",
                      {
                        ref: "table" + tab.name,
                        attrs: {
                          title: "Excel Sheet별 결과 목록",
                          tableId: "excel-upload",
                          editable: _vm.editable,
                          columns: _vm.columns[Number(tab.name)],
                          data: _vm.excelUploadInfo.data[Number(tab.name)]
                            ? _vm.excelUploadInfo.data[Number(tab.name)].filter(
                                (data) =>
                                  !_vm.errorDataCheck || data.error_message
                              )
                            : [],
                          gridHeight: "400px",
                          isExpand: true,
                          filtering: false,
                          columnSetting: false,
                          usePaging: false,
                          selection: "multiple",
                          rowKey: _vm.excelUploadInfo.rowKeys[Number(tab.name)],
                          customTrClass: _vm.setTrClass,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "expand-header",
                              fn: function () {
                                return [_vm._v(" 에러 상세 ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "expand-td",
                              fn: function (props) {
                                return [
                                  _c(
                                    "c-card",
                                    {
                                      staticClass: "excel-upload-status-card",
                                      attrs: { noHeader: true },
                                    },
                                    [
                                      _c("template", { slot: "card-detail" }, [
                                        _c("div", { staticClass: "col-12" }, [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.convertEnter(
                                                  props.row.error_message
                                                )
                                              ),
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "template",
                          { slot: "table-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.excelUploadInfo.data[Number(tab.name)] &&
                                _vm.excelUploadInfo.data[Number(tab.name)]
                                  .length > 0 &&
                                _vm.editable
                                  ? _c("c-btn", {
                                      attrs: {
                                        label: !_vm.errorDataCheck
                                          ? "에러 데이터만 확인"
                                          : "전체보기",
                                        icon: "find_in_page",
                                      },
                                      on: {
                                        btnClicked: function ($event) {
                                          _vm.errorDataCheck =
                                            !_vm.errorDataCheck
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.excelUploadInfo.data[Number(tab.name)] &&
                                _vm.excelUploadInfo.data[Number(tab.name)]
                                  .length > 0 &&
                                _vm.editable
                                  ? _c("c-btn", {
                                      attrs: { label: "제외", icon: "remove" },
                                      on: {
                                        btnClicked: function ($event) {
                                          return _vm.removeData(tab.name)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.tab,
              callback: function ($$v) {
                _vm.tab = $$v
              },
              expression: "tab",
            },
          }),
        ],
        1
      ),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.sameUserDialog,
            callback: function ($$v) {
              _vm.sameUserDialog = $$v
            },
            expression: "sameUserDialog",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "350px" } },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [
                  _vm._v("동명이인 선택"),
                ]),
              ]),
              _c(
                "q-card-section",
                { staticClass: "q-pt-none" },
                _vm._l(_vm.userSameSheet, function (sheet, idx) {
                  return _c(
                    "div",
                    { key: idx },
                    [
                      _c(
                        "q-toolbar",
                        { staticClass: "bg-primary text-white shadow-2" },
                        [
                          _c("q-toolbar-title", [
                            _vm._v("시트명 : " + _vm._s(sheet.sheetName)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        { attrs: { bordered: "" } },
                        _vm._l(sheet.list, function (item, _idx) {
                          return _c(
                            "div",
                            { key: _idx },
                            [
                              _c("q-item-label", { attrs: { header: "" } }, [
                                _vm._v(_vm._s(item.label)),
                              ]),
                              _vm._l(item.userList, function (user, __idx) {
                                return _c(
                                  "q-item",
                                  {
                                    directives: [
                                      { name: "ripple", rawName: "v-ripple" },
                                    ],
                                    key: __idx,
                                    attrs: { tag: "label" },
                                  },
                                  [
                                    _c(
                                      "q-item-section",
                                      { attrs: { avatar: "" } },
                                      [
                                        _c("q-radio", {
                                          attrs: {
                                            val: user.userId,
                                            color: "orange",
                                          },
                                          model: {
                                            value: item.selectData,
                                            callback: function ($$v) {
                                              _vm.$set(item, "selectData", $$v)
                                            },
                                            expression: "item.selectData",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-section",
                                      [
                                        _c("q-item-label", [
                                          _vm._v(_vm._s(user.userName)),
                                        ]),
                                        _c(
                                          "q-item-label",
                                          { attrs: { caption: "" } },
                                          [
                                            _vm._v(
                                              _vm._s(user.userId) +
                                                " / " +
                                                _vm._s(user.deptName)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "q-card-actions",
                { staticClass: "text-primary", attrs: { align: "right" } },
                [
                  _c("q-btn", {
                    attrs: { flat: "", label: "선택" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.selectSameUser.apply(null, arguments)
                      },
                    },
                  }),
                  _c("q-btn", {
                    attrs: { flat: "", label: "취소" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeSameUserPopup.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }