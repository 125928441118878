<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div class="fix-height row excel-upload-cover">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <q-uploader
        flat bordered with-credentials
        ref="excel-upload"
        class="excel-upload"
        capture="environment"
        field-name="file" 
        method="POST"
        :label="label"
        :auto-upload="false"
        :hide-upload-btn="true"
        :multiple="false"
        accept="xlsx"
        :max-file-size="10000000"
        :max-total-size="10000000"
        :max-files="1"
        :no-thumbnails="false"
        :disable="!editable"
        :style="maxheight"
        @rejected="rejected"
        @added="added"
      >
        <!-- :filter="filter" -->
        <template v-slot:header="scope">
          <div class="row no-wrap items-center q-pa-none q-gutter-xs">
            <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />
            <div class="col">
              <div class="q-uploader__title inline-block">{{label}}</div>&nbsp;&nbsp;
              <div class="q-uploader__subtitle inline-block">{{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}</div>
            </div>
            <q-btn
              v-if="editable"  
              class="excel-upload-btn"
              icon="file_download"
              dense outline 
              size="sm"
              label="양식 다운로드"
              @click="downTemplate">
            </q-btn>
            <q-btn v-if="scope.queuedFiles.length > 0&&editable"
              class="excel-upload-btn"
              icon="delete"
              dense outline 
              size="sm"
              label="파일 삭제"
              @click="deleteFile(scope)">
            </q-btn>
          </div>
        </template>
        <template v-slot:list="scope">
          <template v-if="scope.files && scope.files.length > 0">
            <q-list separator>
              <q-item v-for="file in scope.files" :key="file.name">
                <q-item-section>
                  <q-item-label class="full-width ellipsis">
                    {{ file.name }}
                  </q-item-label>

                  <q-item-label caption>
                    Status: {{ file.__status }}
                  </q-item-label>

                  <q-item-label caption>
                    {{ file.__sizeLabel }} / {{ file.__progressLabel }}
                  </q-item-label>
                </q-item-section>

                <q-item-section
                  v-if="file.__img"
                  thumbnail
                  class="gt-xs"
                >
                  <img :src="file.__img.src">
                </q-item-section>
              </q-item>
            </q-list>
          </template>
          <template v-else>
            <div class="empty_dscription">
              <div style="text-align: center">
                <q-chip square icon="info" style="width:100%">
                  Drag or Click
                </q-chip>
              </div>
              <div class="q-pl-md" style="text-align: left">
                <span class="text-positive">업로드 가능 확장자</span>
                : xlsx
                <br/>
                <span class="text-positive">업로드 가능 파일 수</span>
                : 1
                <br/>
                <span class="text-positive">허용 파일 크기</span>
                : 10MB
              </div>
              <q-uploader-add-trigger />
            </div>
          </template>
        </template>
      </q-uploader>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <!-- 엑셀 업로드 현황 -->
      <c-card title="업로드 현황" class="cardClassDetailForm excel-upload-status-card" height="150px">
        <template slot="card-detail">
          <div class="col-12">
            <span v-html="convertEnter(message)"></span>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-12">
      <c-tab
        type="tabcard"
        align="left"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        height="430px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <c-table
            :ref="'table' + tab.name"
            title="Excel Sheet별 결과 목록"
            tableId="excel-upload"
            :editable="editable"
            :columns="columns[Number(tab.name)]"
            :data="excelUploadInfo.data[Number(tab.name)] ? excelUploadInfo.data[Number(tab.name)].filter(data => !errorDataCheck || data.error_message) : []"
            gridHeight="400px"
            :isExpand="true"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            selection="multiple"
            :rowKey="excelUploadInfo.rowKeys[Number(tab.name)]"
            :customTrClass="setTrClass"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="excelUploadInfo.data[Number(tab.name)] && excelUploadInfo.data[Number(tab.name)].length > 0 && editable" 
                  :label="!errorDataCheck ? '에러 데이터만 확인' : '전체보기'" 
                  icon="find_in_page" 
                  @btnClicked="errorDataCheck = !errorDataCheck" />
              </q-btn-group>
              <q-btn-group outline >
                <c-btn v-if="excelUploadInfo.data[Number(tab.name)] && excelUploadInfo.data[Number(tab.name)].length > 0 && editable" 
                  label="제외" 
                  icon="remove" 
                  @btnClicked="removeData(tab.name)" />
              </q-btn-group>
            </template>
            <template v-slot:expand-header>
              에러 상세
            </template>
            <template v-slot:expand-td="props">
              <c-card
                class="excel-upload-status-card"
                :noHeader="true">
                <template slot="card-detail">
                  <div class="col-12">
                    <span v-html="convertEnter(props.row.error_message)"></span>
                  </div>
                </template>
              </c-card>
            </template>
          </c-table>
        </template>
      </c-tab>
    </div>
    <q-dialog v-model="sameUserDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">동명이인 선택</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div v-for="(sheet, idx) in userSameSheet"
            :key="idx">
            <q-toolbar class="bg-primary text-white shadow-2">
              <q-toolbar-title>시트명 : {{sheet.sheetName}}</q-toolbar-title>
            </q-toolbar>
            <q-list bordered>
              <div v-for="(item, _idx) in sheet.list"
                :key="_idx">
                <q-item-label header>{{item.label}}</q-item-label>
                <q-item v-for="(user, __idx) in item.userList"
                  :key="__idx"
                  tag="label" v-ripple>
                  <q-item-section avatar>
                    <q-radio v-model="item.selectData" :val="user.userId" color="orange" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{user.userName}}</q-item-label>
                    <q-item-label caption>{{user.userId}} / {{user.deptName}}</q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </q-list>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="선택" @click.prevent="selectSameUser" />
          <q-btn flat label="취소" @click.prevent="closeSameUserPopup" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar';
import XLSX from 'xlsx'
import selectConfig from '@/js/selectConfig';
export default {
  /* attributes: name, components, props, data */
  name: 'c-excel-upload',
  props: {
    name: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '엑셀 업로드',
    },
    maxheight: {
      type: String,
      default: 'max-height:200px;min-height:200px;width: 100%;',
    },
    excelUploadInfo: {
      type: Object,
      default: function() {
        return {
          rowKeys: [], // 데이터들의 키 속성값
          taskClassCd: '', // 엑셀업로드 양식 구분 값
          sheetNum: 0,
          columns: [],
          data: [],
        }
      }
    },
  },
  data() {
    return {
      errorDataCheck: false,
      fileInfo: {
        oriFileNm: '',
        fileExt: '',
      },
      plantItems: [],
      plantCd: '',
      deptCd: '',
      userList: [],
      userSame: [],
      sameUserDialog: false,
      isError: false,
      message: '',
      templateHeader: [],
      sheetNames: [],
      tab: 0,
      fileInfoUrl: '',
      templateExcelFileUrl: '',
      userListUrl: '',
    };
  },
  computed: {
    columns() {
      let _columns = [];
      if (this.excelUploadInfo.columns && this.excelUploadInfo.columns.length > 0) {
        /**
         * 사업장, 부서, 사용자, 날짜, select box를 제외한 나머지의 경우 type을 text로 변경
         * 단, disabled true인 경우 type none
         */
        this.$_.forEach(this.excelUploadInfo.columns, column => {
          let __columns = [];
          this.$_.forEach(column, item => {
            if (item.disabled) {
              __columns.push({
                name: item.name,
                field: item.name,
                label: item.label,
                align: item.align,
                style: item.style,
                sortable: false,
              })
            } else if (item.type === 'plant') {
              __columns.push({
                name: item.name,
                field: item.name,
                label: item.label,
                align: 'center',
                style: item.style,
                sortable: false,
                type: 'plant',
                required: item.required,
              })
            } else if (item.type === 'dept') {
              __columns.push({
                name: item.name,
                field: item.name,
                label: item.label,
                align: 'center',
                style: item.style,
                sortable: false,
                type: 'dept',
                deptCd: item.deptCd,
                required: item.required,
              })
            } else if (item.type === 'user') {
              __columns.push({
                name: item.name,
                field: item.name,
                label: item.label,
                align: 'center',
                style: item.style,
                sortable: false,
                type: 'user',
                userId: item.userId,
                required: item.required,
              })
            } else if (item.type === 'date') {
              let format = 'YYYY-MM-DD'
              let excelFormat = 'YYYY/MM/DD'
              if (item.type) {
                if (item.type === 'year') {
                  format = 'YYYY'
                  excelFormat = 'YYYY'
                } else if (item.type === 'month') {
                  format = 'YYYY-MM'
                  excelFormat = 'YYYY/MM'
                }
              }
              this.$set(item, 'format', format);
              this.$set(item, 'excelFormat', excelFormat);
              __columns.push({
                name: item.name,
                field: item.name,
                label: item.label,
                align: 'center',
                style: item.style,
                sortable: false,
                type: item.type,
                range: (item.range === true ? true : false),
                required: item.required,
              })
            } else if (item.type === 'select') {
              __columns.push({
                name: item.name,
                field: item.name,
                label: item.label,
                align: 'center',
                style: item.style,
                sortable: false,
                type: 'select',
                comboItems: item.comboItems,
                required: item.required,
              })
            } else if (item.type === 'textarea') {
              __columns.push({
                name: item.name,
                field: item.name,
                label: item.label,
                align: item.align,
                style: item.style,
                sortable: false,
                type: 'textarea',
                required: item.required,
              })
            } else {
              __columns.push({
                name: item.name,
                field: item.name,
                label: item.label,
                align: item.align,
                style: item.style,
                sortable: false,
                type: 'text',
                required: item.required,
              })
            }
          })
          _columns.push(__columns)
        });
      }
      return _columns;
    },
    tabItems() {
      let _items = [];
      if (this.sheetNames && this.sheetNames.length > 0) {
        let idx = 0;
        this.$_.forEach(this.sheetNames, sheet => {
          _items.push({
            name: idx, 
            label: sheet
          })
          idx++;
        });
      }
      return _items;
    },
    userSameSheet() {
      let _userSameSheet = [];
      let sheetArray = this.$_.uniq(this.$_.map(this.userSame, 'sheetNum'));
      if (sheetArray && sheetArray.length > 0) {
        for (let i = 0; i < sheetArray.length; i++) {
          let sheetData = this.$_.filter(this.userSame, { sheetNum: sheetArray[i] })
          this.$_.forEach(sheetData, userData => {
            this.$set(userData, 'selectData', null);
          })
          _userSameSheet.push({
            sheetNum: sheetData[0].sheetNum,
            sheetName: sheetData[0].sheetName,
            list: sheetData
          })
        }
      }
      return _userSameSheet;
    }
  },
  watch: {
    'excelUploadInfo.taskClassCd'() {
      this.getExcelTemplateHeader();
    },
    message() {
      /**
       * 오류 메시지가 있는 경우 Excel 결과 목록에 표시 X
       * 업로더에 올라간 파일로 reset
       */
      if (this.isError) {
        this.excelUploadInfo.data = [];
        this.$refs['excel-upload'].reset();
      }
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // url setting
      this.templateExcelFileUrl = selectConfig.sys.excelUpload.template.url;
      this.fileInfoUrl = selectConfig.com.upload.fileInfo.url
      this.userListUrl = selectConfig.mdm.user.list.url
      // list
      this.$comm.getComboItems('PLANT_CD').then(_result => {
        this.plantItems = _result
      });
      this.getUserList();
      this.getFileInfo();
      this.getExcelTemplateHeader();
    },
    getUserList() {
      this.$http.url = this.userListUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.userList = _result.data;
      },);
    },
    getFileInfo() {
      this.$http.url = this.fileInfoUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        taskClassCd: 'EXCEL_UPLOAD_TEMPLATE',
        taskKey: this.excelUploadInfo.taskClassCd,
      };
      this.$http.request((_result) => {
        this.fileInfo = _result.data;
      },);
    },
    getFileSizeTextByRound(_fileByteSize) {
      return this.$comm.bytesToSize(_fileByteSize);
    },
    added(files) {
      /**
       * 파일을 다시 올리는 경우 업로드 현황 & Excel 결과 목록 초기화
       */
      this.isError = false;
      this.message = '';
      this.excelUploadInfo.data = [];
      /**
       * 엑셀의 헤더가 업로드 된 엑셀과 맞지 않을 경우를 체크
       */
      let thisVue = this;
      let reader = new FileReader(); 
      reader.readAsDataURL(files[0]);
      reader.onload = function () { 
        let result = reader.result;
        
        let base64Data = result.replace(('data:' + files[0].type + ';base64,'), '')
        let cfb = XLSX.read(base64Data, { 
          type: 'base64' 
          ,cellDates: true
        });

        /**
         * 1. 헤더가 맞지 않은 경우 체크
         * 2. 데이터가 없는 경우 체크
         * 3. 넘겨받은 컬럼에 맞춰 유효성 체크
         *  - 필수 값 : required true일 경우 데이터가 비었다는 체크
         *  - 사업장 : 사업장명을 통해 id를 산출
         *  - 부서 : 부서명을 통해 id를 산출
         *  - 사용자 : 사용자명을 통해 id를 산출
         *             사용자 리스트는 행의 사업장 또는 로그인자의 사업장
         *             동명이인이 있을 경우 팝업창 표시 후 사용자 선택 하도록 설정
         *  - 날짜 : default : yyyy-MM-dd (yyyy, yyyy-MM, yyyy-MM-dd)
         *  - select box : list를 받은 후 text에 맞는 걸로 value를 key-in
         *  - text box : 위의 경우를 제외하고는 text box로 사용자가 추가적으로 수정할 수 있게 처리
         *  - 수정을 원치 않는 데이터의 경우 disabled 속성을 받아 처리
         *  - 위의 경우를 제외한 나머지 유효성 체크의 경우 각 화면에서 독자적으로 진행
         * 
         *  ## 유효성 체크에서 탈락한 경우 그 행의 row 색상을 빨간색으로 표시
         */
        let sheetNum = thisVue.excelUploadInfo.sheetNum || 0
        for (let i = 0; i <= sheetNum; i++) {
          let uploadExcel = {
            header: thisVue.$comm.getHeaders(cfb.Sheets[cfb.SheetNames[i]]),
            data: XLSX.utils.sheet_to_json(cfb.Sheets[cfb.SheetNames[i]], { raw: false, cellDates: true }),
          }
          // console.log('## header : ', uploadExcel.header)
          // console.log('## data : ', uploadExcel.data)
          // console.log('## templateHeader : ', thisVue.templateHeader[i])

          // 1. 헤더가 맞지 않은 경우 체크
          let headerCheck = thisVue.templateHeader[i].every((item, idx) => {
            return uploadExcel.header[idx] === item
          })
          if (!headerCheck) {
            thisVue.isError = true;
            thisVue.message += '업로드한 엑셀의 헤더가 올바르지 않습니다. 양식 다운로드하여 확인바랍니다.'
            break;
          }

          // 2. 데이터가 없는 경우 체크
          if (!uploadExcel.data || uploadExcel.data.length === 0) {
            thisVue.isError = true;
            thisVue.message += '업로드한 엑셀에 데이터가 없습니다. 확인 후 업로드 해주십시오.'
            break;
          }

          // 3. 넘겨받은 컬럼에 맞춰 유효성 체크
          thisVue.excelUploadInfo.data[i] = [];
          let rowData = [];
          thisVue.$_.forEach(uploadExcel.data, data => {
            let dataIdx = 0;
            let cellData = {
              error_message: '',
            };
            thisVue.$set(cellData, thisVue.excelUploadInfo.rowKeys[i], uid())
            thisVue.$_.forEach(thisVue.excelUploadInfo.columns[i], item => {
              // *  - 필수 값 : required true일 경우 데이터가 비었다는 체크
              if (item.required && !data[item.label]) {
                cellData['error_message'] += '■ [' + item.label + '] 값은 필수입니다.\n';
                cellData[item.name] = null;
              }
              if (item.type === 'plant') {
                // *  - 사업장 : 사업장명을 통해 id를 산출
                let p_idx = thisVue.$_.findIndex(thisVue.plantItems, { codeName: data[item.label] });
                if (p_idx === -1) {
                  cellData['error_message'] += '■ [' + data[item.label] + '] 값은 관리되지 않는 사업장입니다.\n';
                  cellData[item.name] = null;
                } else {
                  cellData[item.name] = thisVue.plantItems[p_idx].code;
                }
              } else if (item.type === 'dept') {
                // *  - 부서 : 부서명을 통해 id를 산출, row에 사업장이 있을 경우 row에 있는 사업장을 확인 없는 경우 로그인자의 사업장으로 확인
                let plantCd = thisVue.$store.getters.user.plantCd
                let plantName = thisVue.$store.getters.user.plantName
                let checkPlantIdx = thisVue.$_.findIndex(thisVue.excelUploadInfo.columns[i], { type: 'plant' });
                if (checkPlantIdx > -1) {
                  let plant = thisVue.$_.find(thisVue.plantItems, { codeName: data[thisVue.excelUploadInfo.columns[i][checkPlantIdx].label] });
                  // 사업장이 조회되지 않는 경우는 로그인자의 사업장으로 처리
                  if (plant) {
                    plantName = plant.codeName
                    plantCd = plant.code;
                  }
                }
                let d_idx = thisVue.$_.findIndex(thisVue.$store.getters.depts, { plantCd: plantCd, deptName: data[item.label] });
                if (d_idx === -1) {
                  cellData['error_message'] += '■ [' + data[item.label] + '] 값은 \'' + plantName + '\' 사업장에서 관리되지 않는 부서입니다.\n';
                  cellData[item.name] = null;
                } else {
                  cellData[item.name] = thisVue.$store.getters.depts[d_idx].deptName;
                  cellData[item.deptCd] = thisVue.$store.getters.depts[d_idx].deptCd;
                }
              } else if (item.type === 'user') {
                //  *  - 사용자 : 사용자명을 통해 id를 산출
                //  *             사용자 리스트는 행의 사업장 또는 로그인자의 사업장
                //  *             동명이인이 있을 경우 팝업창 표시 후 사용자 선택 하도록 설정
                let plantCd = thisVue.$store.getters.user.plantCd
                let plantName = thisVue.$store.getters.user.plantName
                let checkPlantIdx = thisVue.$_.findIndex(thisVue.excelUploadInfo.columns[i], { type: 'plant' });
                if (checkPlantIdx > -1) {
                  let plant = thisVue.$_.find(thisVue.plantItems, { codeName: data[thisVue.excelUploadInfo.columns[i][checkPlantIdx].label] });
                  // 사업장이 조회되지 않는 경우는 로그인자의 사업장으로 처리
                  if (plant) {
                    plantName = plant.codeName
                    plantCd = plant.code;
                  }
                }
                let u_list = thisVue.$_.filter(thisVue.userList, { plantCd: plantCd, userName: data[item.label] });
                if (!u_list || u_list.length === 0) {
                  cellData['error_message'] += '■ [' + data[item.label] + '] 값은 \'' + plantName + '\' 사업장에서 관리되지 않는 사용자입니다.\n';
                  cellData[item.name] = null;
                } else {
                  if (u_list.length === 1) {
                    cellData[item.name] = u_list[0].userName;
                    cellData[item.userId] = u_list[0].userId;
                  } else {
                    /**
                     * 동명이인이 있는 경우에
                     *  userSame에 해당 정하는 데이터 셋팅
                     *  값은 셋팅하지 않으며 유효성 체크가 끝나고 난 후 동명이인에 대해서 선택하는 팝업 open
                     */
                    if (thisVue.$_.findIndex(thisVue.userSame, { name: item.name }) === -1) {
                      thisVue.userSame.push({
                        sheetNum: i,
                        sheetName: cfb.SheetNames[i],
                        userId: item.userId,
                        name: item.name,
                        label: item.label,
                        userList: u_list,
                      })
                      cellData[item.name] = '';
                      cellData[item.userId] = '';
                    }
                  }
                }
              } else if (item.type === 'date') {
                //  *  - 날짜 : default : yyyy-MM-dd, 이후에 추가할 포맷 : (yyyy, yyyy-MM, yyyy-MM-dd, yyyy-MM-dd HH:mi)
                let date = thisVue.$comm.moment(data[item.label], item.excelFormat).format(item.format);
                if (thisVue.$comm.moment(date, item.format, true).isValid()) {
                  cellData[item.name] = date;
                } else {
                  cellData['error_message'] += '■ [' + date + '] 값은 날짜형식(\'' + item.format + '\')에 맞지 않습니다.\n';
                  cellData[item.name] = null;
                }
              } else if (item.type === 'select') {
                //  *  - select box : list를 받은 후 text에 맞는 걸로 value를 key-in
                let selectData = thisVue.$_.find(item.comboItems, (comboItem) => {
                  return comboItem[(item.valueText ? item.valueText : 'codeName')] === data[item.label]
                });
                if (selectData) {
                  cellData[item.name] = selectData[(item.valueKey ? item.valueKey : 'code')];
                } else {
                  cellData['error_message'] += '■ [' + data[item.label] + '] 값은 관리되는 항목명이 아닙니다.\n';
                  cellData[item.name] = null;
                }
              } else {
                cellData[item.name] = data[item.label];
              }
            });
            rowData.push(cellData);
          })
          thisVue.$set(thisVue.excelUploadInfo.data, i, rowData)
          // console.log(thisVue.excelUploadInfo.data)
          thisVue.message += '<b>■ Sheet 명</b> : ' + cfb.SheetNames[i] + '\n'
            + ' <b>- 총 데이터 수</b> : ' + thisVue.excelUploadInfo.data[i].length + '\n'
            + ' <b>- 문제 <font color="#8BC34A">없는</font> 데이터 수</b> : ' + thisVue.$_.filter(thisVue.excelUploadInfo.data[i], (item) => { return !item.error_message }).length + '\n'
            + ' <b>- 문제 <font color="#F44336">있는</font> 데이터 수</b> : ' + thisVue.$_.filter(thisVue.excelUploadInfo.data[i], (item) => { return item.error_message }).length + '\n'
        }

        if (thisVue.userSame && thisVue.userSame.length > 0) {
          thisVue.sameUserDialog = true
        }
      } 

    },
    rejected(info) {
      if (!info || info.length === 0) {
        return;
      }
      // 다시 파일을 올림으로 인해 메시지를 초기화 처리함
      this.isError = false;
      this.message = '';
      this.$_.forEach(info, reject => {
        // accept, max-file-size, max-total-size, filter, etc
        switch(reject.failedPropValidation) {
          case 'max-file-size': // 파일용량 초과
          case 'max-total-size': // 파일 전체 용량 초과
            this.isError = true;
            this.message += '첨부하신 "' + reject.file.name + '"의 용량이 지정된 용량보다 큽니다. (지정된 용량 : 10MB, 파일 용량 : ' + this.getFileSizeTextByRound(reject.file.size) + ')';
            break;
          case 'max-files': // 업로드 갯수 초과
            this.isError = true;
            this.message = '첨부하신 "' + reject.file.name + '"이 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다. (지정된 업로드 갯수 : 1)';
            break;
          case 'accept': // 확장자 맞지않음
            this.isError = true;
            this.message = '첨부하신 "' + reject.file.name + '"의 확장자가 올바르지 않습니다. (업로드 가능한 확장자 : xlsx)';
            break;
          case 'filter': // filter 걸린경우
            break;
          default:
            break;
        }
      })
    },
    getExcelTemplateHeader() {
      if (!this.excelUploadInfo.taskClassCd) return;
      let thisVue = this;
      this.$http.url = this.templateExcelFileUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        taskClassCd: this.excelUploadInfo.taskClassCd,
      };
      this.$http.request(
        _result => {
          let cfb = XLSX.read(_result.data, {type: 'base64'});
          this.sheetNames = [];
          this.templateHeader = [];
          let sheetNum = this.excelUploadInfo.sheetNum || 0
          for (let i = 0; i <= sheetNum; i++) {
            this.sheetNames.push(cfb.SheetNames[i])
            this.templateHeader.push(thisVue.$comm.getHeaders(cfb.Sheets[cfb.SheetNames[i]]))
          }
        },
      );
    },
    downTemplate(file) {
      let thisVue = this;
      this.$http.url = this.templateExcelFileUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        taskClassCd: this.excelUploadInfo.taskClassCd,
      };
      this.$http.request(
        _result => {
          let url = window.URL || window.webkitURL;
          let link = document.createElement('a');
          let blob = thisVue.$comm.base64ToBlob(_result.data, this.fileInfo.fileExt);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, this.fileInfo.oriFileNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.fileInfo.oriFileNm;
            link.click();
          }
        },
      );
    },
    deleteFile() {
      // this.$refs['excel-upload'].removeQueuedFiles();
      this.isError = false;
      this.message = ''
      this.excelUploadInfo.data = [];
      this.$refs['excel-upload'].reset();
    },
    removeData(idx) {
      let selectData = this.$refs['table' + idx].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.excelUploadInfo.data[idx].splice(this.$_.findIndex(this.excelUploadInfo.data[idx], (gridItem) => {
            return gridItem[this.excelUploadInfo.rowKey] === item[this.excelUploadInfo.rowKey]
          }), 1)
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    setTrClass(props) {
      return props.row.error_message ? 'bg-orange-5' : '';
    },
    convertEnter(str) {
      return str ? str.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
    },
    selectSameUser() {
      this.$_.forEach(this.userSameSheet, sheet => {
        this.$_.forEach(sheet.list, user => {
          this.$_.forEach(this.excelUploadInfo.data[sheet.sheetNum], item => {
            item[user.name] = this.$_.find(user.userList, { userId: user.selectData }).userName
            item[user.userId] = user.selectData
          });
        })
      })

      this.sameUserDialog = false;
    },
    closeSameUserPopup() {
      this.sameUserDialog = false;
    },
  },
};
</script>
<style lang="sass">
.excel-upload-cover
  margin-top: 5px !important
  height: 700px
.uploaded-file-list
  border-top: 2px solid #1ab394
  // max-height: 200px !important
  overflow-y: auto

.q-uploader--disable
  max-height: 36px !important
  min-height: 20px !important

.empty_dscription
  font-size: 14px
  color: #909399
  margin-bottom: 0 !important

.file-origin-name
  line-height: 1.5em!important

.file-description
  .q-field--with-bottom
    padding-bottom: 0px !important
  .q-field--dense .q-field__inner
    padding-bottom: 0px !important

.excel-upload-status-card
  .customCardbody
    padding-top: 0px !important

.excel-upload-btn
  margin: 0px !important
  margin-right: 3px !important
  height: 26px !important
</style>