var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-btn-group",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.btnEditable,
          expression: "btnEditable",
        },
      ],
      staticStyle: { "margin-right": "2px !important" },
      attrs: { outline: "" },
    },
    [
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.data.flag,
            expression: "!data.flag",
          },
        ],
        attrs: {
          color: "blue-7",
          label: `${_vm.data.title} ${"수정"}`,
          icon: "update",
        },
        on: { btnClicked: _vm.updateMode },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.data.flag,
            expression: "data.flag",
          },
        ],
        attrs: { label: "돌아가기", icon: "undo" },
        on: { btnClicked: _vm.back },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }