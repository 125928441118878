class ResizableTable {
  constructor(table) {
    this.table = table;
    this.pageX = undefined;
    this.curCol = undefined;
    this.nxtCol = undefined;
    this.curColWidth = undefined;
    this.offsetWidth = undefined;
    this.nxtColWidth = undefined;
    this.tr = undefined;
    this.ths = [];
    this.divs = [];
    this.resizableTable(table);
  }

  destroy() {
    this.removeAllDivs();
  }

  clearState() {
    this.pageX = undefined;
    this.curCol = undefined;
    this.nxtCol = undefined;
    this.curColWidth = undefined;
    this.offsetWidth = undefined;
    this.nxtColWidth = undefined;
  }

  onMouseDown(e) {
    e.stopPropagation();
    e.preventDefault();
    this.curCol = e.target.parentElement;
    this.nxtCol = this.curCol.nextElementSibling;
    this.pageX = e.pageX;

    const padding = this.paddingDiff(this.curCol);

    this.offsetWidth = this.curCol.offsetWidth;
    this.curColWidth = this.offsetWidth - padding;
    if (this.nxtCol) {
      this.nxtColWidth = this.nxtCol.offsetWidth - padding;
    }
  }

  onMouseOver(e) {
    e.target.style.borderRight = "2px solid #c0c0c0";
  }

  onMouseOut(e) {
    e.target.style.borderRight = "";
  }

  onMouseMove(e) {
    if (this.curCol) {
      const diffX = e.pageX - this.pageX;

      if (this.nxtCol) {
        this.nxtCol.style.width = this.nxtColWidth - diffX + "px";
      }

      this.curCol.style.width = this.curColWidth + diffX + "px";
    }
  }

  onMouseUp(e) {
    this.clearState();
    e.stopPropagation();
    e.preventDefault();
  }

  setListeners(div) {
    div.addEventListener("mousedown", (e) => this.onMouseDown(e));
    div.addEventListener("mousedown", (e) => this.onMouseDown(e), true);
    div.addEventListener("mouseover", (e) => this.onMouseOver(e));
    div.addEventListener("mouseout", (e) => this.onMouseOut(e));
    document.addEventListener("mousemove", (e) => this.onMouseMove(e));
    document.addEventListener("mouseup", (e) => this.onMouseUp(e));
  }

  removeListener(div) {
    div.removeEventListener("mousedown", (e) => this.onMouseDown(e));
    div.removeEventListener("mouseover", (e) => this.onMouseOver(e));
    div.removeEventListener("mouseout", (e) => this.onMouseOut(e));
    document.removeEventListener("mousemove", (e) => this.onMouseMove(e));
    document.removeEventListener("mouseup", (e) => this.onMouseUp(e));
  }

  removeListeners() {
    this.divs.forEach((div) => this.removeListener(div));
  }

  createHeader(tableHeight, firstTrHeight, colLength) {
    const tr = document.createElement("tr");
    for (let i = 0; i < colLength; i++) {
      const th = document.createElement("th");
      const div = this.createDiv(firstTrHeight);
      th.appendChild(div);
      this.setListeners(div);
      th.style.position = "sticky";
      th.style.whiteSpace = "nowrap";
      th.style.textOverflow = "ellipsis";
      th.style.padding = "0px";
      th.style.top = firstTrHeight + "px";
      th.style.border = "none";
      tr.appendChild(th);
      this.divs.push(div)
      this.ths.push(th)
    }
    tr.setAttribute('id', 'resize-tr');
    tr.setAttribute('name', 'resize-tr');
    tr.style.height = "0px";
    tr.style.padding = "0px";
    return tr;
  }
  createDiv(height) {
    const div = document.createElement("div");
    div.style.top = "-" + height + "px";
    // div.style.top = 0;
    div.style.right = 0;
    div.style.width = "5px";
    div.style.position = "absolute";
    div.style.cursor = "col-resize";
    div.style.userSelect = "none";
    div.style.height = height + "px";
    div.style.zIndex = 100;
    return div;
  }

  removeDiv(div) {
    if (div && div.parentElement) {
      div.parentElement.removeChild(div);
    }
  }

  removeAllDivs() {
    if (this.divs) {
      this.divs.forEach((div) => {
        this.removeListener(div);
        this.removeDiv(div);
      });
    }
    if (this.ths) {
      this.ths.forEach((th) => {
        th.parentNode.removeChild(th);
      });
    }
    if (this.tr) {
      this.tr.parentNode.removeChild(this.tr);
      this.tr = undefined;
    } else {
      const headers = this.table.getElementsByTagName("thead"),
        header = headers[0],
        trs = header.getElementsByTagName("tr");
      for (let i = 0; i < trs.length; i++) {
        if (trs[i].id === 'resize-tr') {
          trs[i].parentNode.removeChild(trs[i]);
          break;
        }
      }
    }
    if (this.ths) {
      this.ths.splice(0, this.ths.length);
    }
    if (this.divs) {
      this.divs.splice(0, this.divs.length);
    }
  }

  paddingDiff(col) {
    const style = window.getComputedStyle(col, null);

    if (style.getPropertyValue("box-sizing") === "border-box") {
      return 0;
    }

    const padLeft = style.getPropertyValue("padding-left");
    const padRight = style.getPropertyValue("padding-right");
    return parseInt(padLeft, 10) + parseInt(padRight, 10);
  }

  getStyleVal(el, css) {
    return window.getComputedStyle(el, null).getPropertyValue(css);
  }

  resizableTable() {
    const tbodys = this.table.getElementsByTagName("tbody"),
      rows = tbodys && tbodys.length === 1 ? tbodys[0].getElementsByTagName("tr") : tbodys[1].getElementsByTagName("tr"),
      headers = this.table.getElementsByTagName("thead"),
      header = headers[0],
      cols = rows[0] ? rows[0].children : undefined;
      // cThead = document.createElement("thead");
    if (!cols || !header) return;
    const trs = header.getElementsByTagName("tr");
    if (!trs || trs.length === 0) return;

    for (let i = 0; i < trs.length; i++) {
      if (trs[i].id === 'resize-tr') {
        return;
      }
    }
    const tableHeight = this.table.offsetHeight;
    const firstTrHeight = header.offsetHeight;
    const newHeader = this.createHeader(tableHeight, firstTrHeight, cols.length);
    // this.table.insertBefore(cThead, tbodys[0]);
    header.appendChild(newHeader)
    this.tr = newHeader;
  }
}

function handleUpdate(el) {
  const ctx = el.__tableResizable;
  const tables = el.getElementsByTagName("table");
  if (tables === undefined && ctx !== undefined) {
    // table turned into a grid?
    ctx.destroy();
    delete el.__tableResizable;
  } else if (tables !== undefined && ctx === undefined) {
    // new table
    setTimeout(() => {
      el.__tableResizable = new ResizableTable(tables[0]);
    }, 250);
  } else if (tables !== undefined && ctx !== undefined) {
    // use-case is columns added/removed
    // tear down old one and add new one
    ctx.destroy();
    delete el.__tableResizable;
    setTimeout(() => {
      el.__tableResizable = new ResizableTable(tables[0]);
    }, 250);
  }
}

/* eslint-disable no-unused-vars */
const TableResizable = {
  name: "tableResizable",

  mounted(el, binding) {
  },

  bind(el, binding) {
    // handleUpdate(el, binding);
  },

  componentUpdated(el, binding) {
    handleUpdate(el, binding);
  },

  unbind(el) {
    const ctx = el.__tableResizable;
    if (ctx) {
      ctx.destroy();
      delete el.__tableResizable;
    }
  },

  beforeUnmount(el) {
    const ctx = el.__tableResizable;
    if (ctx) {
      ctx.destroy();
      delete el.__tableResizable;
    }
  }
};

export default TableResizable