var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-field", {
        ref: "customField",
        class: [_vm.beforeText ? "before-field" : ""],
        attrs: {
          dense: _vm.dense,
          "stack-label": "",
          color: "orange-7",
          label: _vm.label,
          disable: _vm.disabled || !_vm.editable,
          rules: [_vm.myRule],
        },
        scopedSlots: _vm._u(
          [
            _vm.label
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            [
                              !_vm.required
                                ? _c("i", {
                                    staticClass:
                                      "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                                  })
                                : _vm._e(),
                              _c("font", { staticClass: "formLabelTitle" }, [
                                _vm._v(_vm._s(_vm.label)),
                              ]),
                              _vm.required
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons labelfrontIcon text-requiredColor",
                                    },
                                    [_vm._v("check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "control",
              fn: function () {
                return _vm._l(_vm.users, function (user, index) {
                  return _c(
                    "q-chip",
                    {
                      key: index,
                      staticClass: "q-ma-none customchipdept",
                      attrs: {
                        outline: "",
                        square: "",
                        removable: !_vm.disabled && _vm.editable,
                        color: "primary",
                        "text-color": "white",
                      },
                      on: {
                        remove: function ($event) {
                          return _vm.remove(user)
                        },
                      },
                    },
                    [
                      _vm.type === "user"
                        ? [_vm._v(" " + _vm._s(user.userName) + " ")]
                        : _vm.type === "dept_user"
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(user.deptName) +
                                " / " +
                                _vm._s(user.userName) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                })
              },
              proxy: true,
            },
            {
              key: "before",
              fn: function () {
                return [
                  _vm.beforeText
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white",
                          },
                          [_vm._v(" " + _vm._s(_vm.beforeText) + " ")]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "append",
              fn: function () {
                return [
                  !_vm.disabled && _vm.editable
                    ? _c("q-icon", {
                        staticClass: "cursor-pointer",
                        attrs: { name: "search" },
                        on: { click: _vm.search },
                      })
                    : _vm._e(),
                  !_vm.disabled && _vm.editable
                    ? _c("q-icon", {
                        staticClass: "cursor-pointer",
                        attrs: { name: "close" },
                        on: { click: _vm.reset },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.users,
          callback: function ($$v) {
            _vm.users = $$v
          },
          expression: "users",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }