var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-header header-shadow", class: _vm.headerbg },
    [
      _vm.isMain
        ? _c("div", {
            staticClass: "logo-src anivertilogo",
            on: { click: _vm.routeMain },
          })
        : _c("div", { staticClass: "logo-src-label slidelogo" }, [
            _vm._v(" " + _vm._s(_vm.menuTitle) + " "),
          ]),
      _c("div", { staticClass: "app-header__content" }, [
        _c("div", { staticClass: "app-header-left" }, [
          _c("div", { staticClass: "header__pane ml-auto menucollapse" }, [
            _c(
              "button",
              {
                staticClass: "hamburger close-sidebar-btn hamburger--elastic",
                class: { "is-active": !_vm.isOpen },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.toggleBodyClass("closed-sidebar")
                  },
                },
              },
              [_vm._m(0)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "app-header-right" }),
      ]),
      _c("div", { staticClass: "app-header__mobile-menu" }),
      _c("div", { staticClass: "app-header__menu" }, [
        _c(
          "div",
          { staticClass: "q-mt-lg" },
          [
            _c("UserArea"),
            _c(
              "div",
              { staticClass: "alimBell" },
              [
                _c(
                  "q-btn",
                  {
                    staticClass: "topnoti",
                    attrs: {
                      flat: "",
                      dense: "",
                      round: "",
                      color: "white",
                      icon: "notifications",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sys/appr/doc/apprTodo")
                      },
                    },
                  },
                  [
                    _vm.isBadge
                      ? _c("q-badge", {
                          attrs: {
                            color: "orange",
                            label: _vm.notiCnt,
                            floating: "",
                            rounded: "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }