export default {
  props: {
  },
  computed: {
    convertLabel() {
      // return this.label ? this.$comm.getLangLabel(this.label) : ''
      return this.label
    },
    convertTitle() {
      // return this.title ? this.$comm.getLangLabel(this.title) : ''
      return this.title
    },
    convertPlaceholder() {
      // return this.placeholder ? this.$comm.getLangLabel(this.placeholder) : ''
      return this.placeholder
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
