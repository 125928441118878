<!--
사용자 멀티 선택
 : 사업장(선택적)에 해당하는 사용자들을 선택하여 chip형태로 표시
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customField"
      :class="[beforeText ? 'before-field' : '']"
      :dense="dense"
      stack-label
      color="orange-7"
      :label="label"
      :disable="disabled||!editable"
      :rules="[myRule]"
      v-model="users"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <q-chip
          v-for="(user, index) in users"
          :key="index"
          outline square 
          :removable="!disabled&&editable"
          color="primary"
          text-color="white"
          class="q-ma-none customchipdept"
          @remove="remove(user)">
          <template v-if="type === 'user'">
            {{user.userName}}
          </template>
          <template v-else-if="type === 'dept_user'">
            {{user.deptName}} / {{user.userName}}
          </template>
        </q-chip>
      </template>

      <template v-slot:before>
        <template v-if="beforeText">
          <div class="custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white">
            {{beforeText}}
          </div>
        </template>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  /* attributes: name, components, props, data */
  name: 'c-multi-field',
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: [String, Array],
    },
    userInfo: {
      type: Object,
      default: function() {
        return {
          userItems: [],
          userText: 'userName',
          userValue: 'userId',
          deptText: 'deptName',
          deptValue: 'deptCd',
        }
      },
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'user', // user dept_user plant_dept_user
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    beforeText: {
      type: String,
      default: '',
    },
    plantCd: {
      type: String,
      default: '',
    },
    isArray: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vValue: null,
      userList: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '100%',
        param: {},
        closeCallback: null,
      },
      setEvent: false,
      isString: true,
    };
  },
  computed: {
    users() {
      let _users = [];
      if (this.userList && this.userList.length > 0 && this.value && this.value.length > 0) {
        let splitUserId = [];
        if (typeof this.vValue === 'string') {
          // 문자열로 들어온 경우
          splitUserId = this.vValue.split(',')
        } else {
          // 배열로 들어온 경우
          splitUserId = this.$_.cloneDeep(this.vValue)
        }
        this.$_.forEach(splitUserId, userId => {
          let data = this.$_.find(this.userList, { userId: userId })
          if (data) {
            _users.push({
              userId: data.userId,
              userName: data.userName,
              deptCd: data.deptCd,
              deptName: data.deptName,
            })
          }
        })
      }
      return _users;
    }
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
    users: {
      handler: function () {
        if (this.users && this.users.length > 0) {
          if (this.userInfo.userItems && this.userInfo.userItems.length > 0) {
            let deleteUserIdx = [];
            this.$_.forEach(this.userInfo.userItems, user => {
              let data = this.$_.find(this.users, (item) => {
                return user[this.userInfo.userValue] === item.userId
              });
              if (data) {
                this.$set(user, this.userInfo.userValue, data.userId)
                this.$set(user, this.userInfo.userText, data.userName)
                if (this.type === 'dept_user') {
                  this.$set(user, this.userInfo.deptValue, data.deptCd)
                  this.$set(user, this.userInfo.deptText, data.deptName)
                }
              } else {
                deleteUserIdx.push(this.$_.findIndex(this.userInfo.userItems, (item) => {
                  return item[this.userInfo.userValue] === user[[this.userInfo.userValue]]
                }))
              }
            })
            this.$_.forEach(deleteUserIdx, idx => {
              this.userInfo.userItems.splice(idx, 1)
            })

            this.$_.forEach(this.users, user => {
              if (this.$_.findIndex(this.userInfo.userItems, (item) => {
                return item[this.userInfo.userValue] === user.userId
              }) === -1) {
                let pushData = {};
                this.$set(pushData, this.userInfo.userValue, user.userId)
                this.$set(pushData, this.userInfo.userText, user.userName)
                if (this.type === 'dept_user') {
                  this.$set(pushData, this.userInfo.deptValue, user.deptCd)
                  this.$set(pushData, this.userInfo.deptText, user.deptName)
                }
                this.userInfo.userItems.push(pushData)
              }
            })
          } else {
            this.$_.forEach(this.users, user => {
              let pushData = {};
              this.$set(pushData, this.userInfo.userValue, user.userId)
              this.$set(pushData, this.userInfo.userText, user.userName)
              if (this.type === 'dept_user') {
                this.$set(pushData, this.userInfo.deptValue, user.deptCd)
                this.$set(pushData, this.userInfo.deptText, user.deptName)
              }
              this.userInfo.userItems.push(pushData)
            })
          }
        } else {
          if (this.userInfo.userItems && this.userInfo.userItems.length > 0) {
            this.userInfo.userItems.splice(0, this.userInfo.userItems.length)
          }
          // this.$set(this.userInfo, 'userItems', [])
        }
      },
      deep: true,
    },
    disabled() {
      if (!this.setEvent && !this.disabled) {
        this.setEvent = true;
        this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
      }
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    if (typeof this.vValue === 'string') {
      this.isString = true;
    } else {
      this.isString = false;
    }
    this.listUrl = selectConfig.mdm.user.list.url
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.setEvent = true;
      this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    getUserList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd
      }
      this.$http.request((_result) => {
        this.userList = _result.data;
        this.setValueText();
      },);
    },
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        if (!this.userList) {
          this.getUserList();
        } else if (this.userList.length === 0) {
          this.$emit('input', null);
        }
      }
    },
    search() {
      this.popupOptions.title = '사용자 검색'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let emitUsers = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.users, { userId: item.userId }) === -1) {
            emitUsers.push(item)
          }
        })
        let emitUserId = this.$_.cloneDeep(this.vValue);
        if (typeof this.vValue === 'string' || this.isString || !this.isArray) {
          // 문자열로 들어온 경우
          // 문자열이 있는경우 ',' 붙임.
          if (emitUserId) {
            emitUserId += ',' + this.$_.map(emitUsers, 'userId').toString()
          } else {
            emitUserId = this.$_.map(emitUsers, 'userId').toString()
          }
        } else {
          // 배열로 들어온 경우
          if (emitUserId) {
            emitUserId = this.$_.concat(emitUserId, this.$_.map(emitUsers, 'userId'))
          } else {
            emitUserId = this.$_.map(emitUsers, 'userId')
          }
        }
        this.$emit('input', emitUserId);
        this.$emit('dataChange', emitUserId);
      }
    },
    reset() {
      this.$emit('input', null);
      this.$emit('dataChange', null);
    },
    remove(user) {
      let emitUserId;
      let splitUserId = [];
      if (typeof this.vValue === 'string') {
        // 문자열로 들어온 경우
        splitUserId = this.$_.cloneDeep(this.vValue).split(',')
        splitUserId.splice(this.$_.indexOf(splitUserId, user.userId), 1);
        emitUserId = splitUserId.toString()
      } else {
        // 배열로 들어온 경우
        splitUserId = this.$_.cloneDeep(this.vValue)
        splitUserId.splice(this.$_.indexOf(splitUserId, user.userId), 1);
        emitUserId = splitUserId
      }
      this.$emit('input', emitUserId);
      this.$emit('dataChange', emitUserId);
    },
    myRule(val) {
      return !this.required || (val && val.length > 0) || ''
    },
  },
};
</script>
<!-- <style lang="sass">
.customDate
  .q-field__before
    padding-top: 5px
.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important

.custom-field
  .q-field__native
    cursor: pointer
  </style> -->