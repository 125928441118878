<!--
목적 : 팝업
Detail :
 *
examples:
 *
-->
<template>
  <div>
    <q-dialog
      no-esc-dismiss
      no-backdrop-dismiss
      :content-class="{ customDialog: true, 'full-dialog': param.isFull, 'mini-dialog': !param.isFull }"
      :content-style="{ 'opacity' : bodyOpacity }"
      :position="param.position ? param.position : (!param.isFull ? 'bottom' : 'bottom')"
      v-model="param.visible">
      <q-layout
        ref="dialog-layout"
        view="Lhh lpR fff"
        class="bg-white no-scroll"
        :style="{ 'max-width': param.isFull ? 'none' : param.width, 'min-height': 'auto', 'max-height': param.isFull ? '100%' : 'calc(100vh - 50px)' }">
        <q-header ref="dialog-header" class="text-white" :class="param.isFull ? (param.isApproval ? 'bg-purple-8' : 'bg-indigo-8') : 'bg-orange-7'">
          <q-toolbar>
            <q-toolbar-title>{{param.title}}</q-toolbar-title>
            <q-btn flat @click="closePopup" round dense icon="close">
            </q-btn>
          </q-toolbar>
        </q-header>
        <q-drawer side="right" bordered v-model="subMenuOpen" :width="200" :breakpoint="300" content-class="bg-grey-3 q-pa-sm">
          <div v-for="n in 50" :key="n">Drawer {{ n }} / 50</div>
        </q-drawer>
        <q-page-container 
          ref="dialog-container" container
          id="dialog-container"
          class="custom-dialog-page-container"
          :style="{ 'overflow-y': 'auto', 'min-height': 'auto', 'max-height': (param.isFull ? 'calc(100vh)' : 'calc(100vh - 100px)') }">
          <q-page padding style="min-height:200px;">
            <div :style="{ 'height': param.isFull ? 'calc(100vh - 90px)' : 'auto', }">
              <q-scroll-observer :scroll-target="$refs['dialog-container']" @scroll="onScroll" />
              <component
                :is="param.target"
                :style="`background:` + bodyColor"
                :popupParam="param.param"
                :contentHeight="contentHeight"
                :returnData="returnData"
                :eventScroll.sync="eventScroll"
                @closePopup="param.closeCallback"
              />
            </div>
            <div class="popupBackBtn2" v-show="!param.isFull">
              <q-btn flat color="white" text-color="white" icon="close" @click="param.closeCallback" />
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
var isChrome = /*@cc_on!@*/false || !document.documentMode
import { uid } from 'quasar'
export default {
  name: 'c-dialog',
  props: {
    param: {
      type: Object,
      default: () => ({
        isFull: true,
        isApproval: false,
        target: null,
        title: '',
        visible: false,
        width: '',
        height: null,
        top: '',
        param: {},
        closeCallback: null,
        position: 'top',
      }),
    },
  },
  data() {
    return {
      bodyColor: '#FFFFFF',
      bodyOpacity: '0.99 !important',
      bodyTimer: '',
      subMenuOpen: false,
      maximizedToggle: true,
      contentHeight: 'auto',
      returnData: { // 최대 3개의 data를 return
        col1: null,
        col2: null,
        col3: null,
      },
      isFab: false,
      fab: false,
      eventScroll: {
        save: null,
        isSave: false,
      },
      scrollInfo: {
        position: null,
        direction: null,
        directionChanged: false,
        inflexionPosition: null
      }
    };
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.param.visible && this.bodyTimer) {
      clearTimeout(this.bodyTimer)
    }
    if (!isChrome) {
      this.bodyOpacity = '0.99 !important';
    }
    window.removeEventListener('resize', this.setSize);
  },
  destroyed() {},
  updated() {
    if (this.param.visible) {
      if (!isChrome) {
        this.bodyTimer = setTimeout(this.setBodyTimer,2000)
      } else {
        this.bodyOpacity = '1 !important';
      }
    } else {
      if (!isChrome) {
        this.bodyOpacity = '0.99 !important';
      }
    }
  },
  watch: {
    'param.visible'() {
      this.setSize();
    }
  },
  /* methods */
  methods: {
    init() {
    },
    setBodyTimer() {
      this.bodyOpacity = '1 !important';
      clearTimeout(this.bodyTimer)
    },
    setSize() {
      if (this.param.visible) {
        setTimeout(() => {
          let height = this.$refs['dialog-layout'].$el.offsetHeight - this.$refs['dialog-header'].$el.offsetHeight;
          this.contentHeight = !isNaN(height) ? height : 'auto';
        }, 300);
      } else {
        this.contentHeight = 'auto'
      }
    },
    closePopup() {
      this.param.closeCallback(this.returnData)
    },
    onScroll (info) {
      this.scrollInfo = info
      if (this.eventScroll.position) {
        // 설정한 위치가 있으며 현재 스크롤 위치와 동일 또는 큰 경우
        if (this.scrollInfo.direction === 'down' && this.eventScroll.position <= this.scrollInfo.position) {
          // 아래로 스크롤링 하는 경우
          this.isFab = true;
        } else if (this.scrollInfo.direction === 'up' && this.eventScroll.position > this.scrollInfo.position) {
          // 위로 스크롤링 하는 경우
          this.isFab = false;
        }
      } else {
        this.isFab = false;
      }
    },
    goSave() {
      this.eventScroll.save = uid();
    },
  },
};
</script>
<style lang="sass">
.q-dialog__inner--minimized
  padding: 0px !important
.q-toolbar
  min-height: 40px
  .q-toolbar__title
    font-size: 1.2em
    font-weight: 700

.full-dialog
  .q-dialog__inner
    height: 100%
.savebtnback
  margin-right: 20px

  
.bottomBtn
  // margin-bottom: 20px !important
  position: fixed
  bottom: 0
  left: 0
  right: 0
  height: 35px
  background: white
  // border-top: solid 2px #FFC107
  .backLink
    font-weight: 700
    font-size: 1.4em
    // display: block
    // line-height: 2.2rem
    // height: 2.2rem
    height: 100%
    // padding: 0.1rem 1.2rem 0 1.6rem
    padding: 0 !important
    position: relative
    border-radius: 0
    color: red !important
    white-space: nowrap
    -webkit-transition: all 0.2s
    transition: all 0.2s
    text-decoration: none
    text-align: center
    button
      border-radius: 0 !important
.mini-dialog .q-layout
  border-radius: 16px 16px 0 0 !important
  margin-left: 16px !important
  margin-right: 16px !important
</style>