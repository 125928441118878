<template>
  <div :class="headerbg" class="app-header header-shadow">
    <div v-if="isMain" class="logo-src anivertilogo" @click="routeMain" />
    <div v-else class="logo-src-label slidelogo">
      {{menuTitle}}
    </div>
    <div class="app-header__content">
      <div class="app-header-left">

      <div class="header__pane ml-auto menucollapse">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active' : !isOpen }" @click="toggleBodyClass('closed-sidebar')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
        <!-- <TabMenus /> -->
        <!-- <SearchBox /> -->
        <!-- <MegaMenu /> -->
      </div>
      <div class="app-header-right">
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <!-- <div>
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div> -->
    </div>
    <div class="app-header__menu">
      <div class="q-mt-lg">
        <UserArea />
        <!-- <b-button class="btn-icon btn-icon-only" variant="white" size="sm"
          v-bind:class="{ 'active' : isOpenMobileMenu }" @click="toggleMobile2('header-menu-open')">
          <div class="btn-icon-wrapper text-white">
            <font-awesome-icon icon="ellipsis-v" />
          </div>
        </b-button> -->
        <!-- <q-fab
          v-show="!isMain"
          v-model="searchAreaShow"
          color="white"
          icon="add"
          direction="down"
          vertical-actions-align="left"
          padding="xs"
          flat
          class="searchAreaShowBtn"
          label="검색조건"
          @show="searchAreaToggle"
          @hide="searchAreaToggle"
        >
        </q-fab> -->
        <!-- <div class="alimqr">
          <q-btn
            flat
            dense
            round
            color="white"
            class="topnoti"
            icon="account_circle"
            @click="goPage('/sys/appr/qrreader')">
          </q-btn>
        </div> -->
        <div class="alimBell">
          <q-btn
            flat
            dense
            round
            color="white"
            class="topnoti"
            icon="notifications"
            @click="goPage('/sys/appr/doc/apprTodo')">
            <q-badge color="orange" v-if="isBadge" :label="notiCnt" floating rounded />
          </q-btn>
        </div>
        <!-- <div class="alimHome">
          <q-btn
            flat
            dense
            v-show="isBadge"
            round
            color="white"
            class="topnoti"
            icon="home"
            @click="goPage('/')">
          </q-btn>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import SearchBox from "./Header/SearchBox";
// import MegaMenu from "./Header/MegaMenu";
// import TabMenus from "./Header/TabMenus";
// import ShortTabMenus from "./Header/TabShortMenus";
// import HeaderDots from "./Header/HeaderDots";
import UserArea from "./Header/HeaderUserArea";
// import Favorites from "./Header/HeaderFavorite";
// import HeaderZoom from "./Header/HeaderZoom";

import selectConfig from '@/js/selectConfig';
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// library.add(faEllipsisV);
export default {
  name: "Header",
  components: {
    // TabMenus,
    // ShortTabMenus,
    // // SearchBox,
    // // MegaMenu,
    // HeaderDots,
    UserArea,
    // Favorites,
    // HeaderZoom,
    // "font-awesome-icon": FontAwesomeIcon
  },

  data() {
    return {
      notiCnt: 0,
      isOpen: false,
      isOpenMobileMenu: false,
      searchAreaShow: false,
      isBadge: false,
      apprCntUrl: '',
      apprCnt1: 0,
      apprCnt2: 0,
      apprCnt3: 0,
      apprCnt4: 0,
    };
  },
  props: {
    headerbg: String
  },
  computed: {
    changeSearchAreaShow() {
      return this.$store.state.app.searchAreaToggle;
    },
    isMain() {
      return this.$route.name === 'main' || this.$route.path === '/' || this.$route.path === '/main/index';
    },
    menuTitle() {
      let data = this.$_.find(this.$store.getters.addRouters, { name: this.$route.name });
      return data ? data.meta.title : ''
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    changeSearchAreaShow() {
      this.searchAreaShow = this.$_.clone(this.changeSearchAreaShow)
    }
  },
  methods: {
    init() {
      this.apprCntUrl = selectConfig.sys.appr.list.mycnt.url;
      this.getApprCnt();
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
      const el = document.body;
      el.classList.remove('closed-sidebar-open');
    },
    getApprCnt() {
      this.$http.url = this.apprCntUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        approvalUserId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.apprCnt1 = _result.data.apprCnt1;
        this.apprCnt2 = _result.data.apprCnt2;
        this.apprCnt3 = _result.data.apprCnt3;
        this.apprCnt4 = _result.data.apprCnt4;
        if (this.apprCnt1 + this.apprCnt2 + this.apprCnt3 + this.apprCnt4 > 0) {
          this.isBadge = true;
          this.notiCnt = this.apprCnt1 + this.apprCnt2 + this.apprCnt3 + this.apprCnt4;
        }
      },);
    },
    toggleBodyClass(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleMobile(className) {
      const el = document.body;

      if (el.classList.contains(className)) {
        el.classList.remove(className);
      } else {
        el.classList.add(className);

        let _leftMenu = document.getElementsByClassName('left-sidebar-menu')
        if (_leftMenu && _leftMenu[0]) {
          _leftMenu[0].focus();
        }
      }
    },
    toggleMobile2(className) {
      const el = document.body;
      this.isOpenMobileMenu = !this.isOpenMobileMenu;

      if (this.isOpenMobileMenu) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    routeMain() {
      this.$router.push('/');
    },
    searchAreaToggle() {
      this.$store.dispatch('searchAreaToggle', this.searchAreaShow);
      const el = document.body;
      el.classList.remove('closed-sidebar-open');
    }
  }
};
</script>
<style lang="sass">
.menucollapse .hamburger-inner, .menucollapse .hamburger-inner::before, .menucollapse .hamburger-inner::after
  background: #fff !important
.searchAreaShowBtn
  position: fixed
  top: 7px
  right: 40px
  z-index: 9999
  .q-fab__label--internal-right
    padding-left: 0em
.alimBell
  position: fixed
  top: 7px
  left: 5px
  z-index: 9999
.alimqr
  position: fixed
  top: 7px
  right: 5px
  z-index: 9999
.alimHome
  position: fixed
  top: 6px
  left: 80px
  z-index: 9999
</style>
