/**
 * 조회용 config
 */
let selectConfig = {
  auth: {
    login: {
      refresh: {
        url: "/api/auth/refresh"
      },
      valid: {
        url: "/api/auth/valid"
      }
    },
    userInfo: {
      url: "/api/auth/user/info",
      depts: {
        url: "/api/auth/user/info/depts"
      },
      users: {
        url: "/api/auth/user/info/users"
      },
      vendors: {
        url: "/api/auth/user/info/vendors"
      },
    },
  },
  system: {
    lang: {
      url: "/api/system/langs"
    },
    genJson: {
      url: "/api/system/lang/json"
    },
  },
  com: {
    revision: {
      getversion: '/api/com/revision'
    },
    upload: {
      fileList: {
        url: "/api/com/uploadedFiles"
      },
      fileInfo: {
        url: "/api/com/uploadedFile"
      },
      preview: {
        url: "/api/com/imageToByte"
      },
      previewParamTask: {
        url: "/api/com/imageToByte/task"
      },
      fileDown: {
        url: "/api/com/fileToByte"
      },
    },
    weeks: {
      list: {
        url: '/api/com/weeks'
      }
    },
    task: {
      url: '/api/com/tasks'
    },
  },
  devtool: {
    dbtables: {
      url: "/api/devtool/generators"
    },
    dbtable: {
      url: "/api/devtool/generator/{0}/{1}"
    }
  },
  wod: {
    workreq: {
      list: {
        url: '/api/wod/workreqs'
      },
      get: {
        url: '/api/wod/workreq'
      },
      depts: {
        url: '/api/wod/workreq/depts'
      },
    },
    workorder: {
      daily: {
        list: {
          url: '/api/wod/workorder/dailyplans'
        },
        get: {
          url: '/api/wod/workorder/result/work/daily'
        },
      },
      cost: {
        list: {
          url: '/api/wod/workorder/result/cost/list'
        },
      },
      plan: {
        list: {
          url: '/api/wod/workorder/plans'
        },
        get: {
          url: '/api/wod/workorder/plan'
        },
        print: {
          url: '/api/wod/workorder/plan/{0}'
        },
        copy: {
          url: '/api/wod/workorder/plan/copy'
        },
        all: {
          url: '/api/wod/workorder/plan/all'
        },
        equip: {
          url: '/api/wod/workorder/plan/equips'
        },
        cost: {
          url: '/api/wod/workorder/plan/costs'
        },
        workers: {
          url: '/api/wod/workorder/plan/workers'
        },
        work: {
          list: {
            url: '/api/wod/workorder/plan/works'
          },
          get: {
            url: '/api/wod/workorder/plan/work'
          },
          material: {
            url: '/api/wod/workorder/plan/materials'
          },
          service: {
            url: '/api/wod/workorder/plan/services'
          },
          allsubs: {
            url: '/api/wod/workorder/plan/allsubs'
          }
        },
      },
      result: {
        get: {
          url: '/api/wod/workorder/result'
        },
        equip: {
          url: '/api/wod/workorder/result/equips'
        },
        cost: {
          url: '/api/wod/workorder/result/costs'
        },
        inout: {
          url: '/api/wod/workorder/result/inouts'
        },
        workers: {
          url: '/api/wod/workorder/result/workers'
        },
        work: {
          list: {
            url: '/api/wod/workorder/result/works'
          },
          get: {
            url: '/api/wod/workorder/result/work'
          },
          material: {
            url: '/api/wod/workorder/result/materials'
          },
          service: {
            url: '/api/wod/workorder/result/services'
          },
          breakdown: {
            url: '/api/wod/workorder/result/breakdown'
          },
          allsubs: {
            url: '/api/wod/workorder/result/allsubs'
          }
        },
      },
    },
  },
  sp: {
    material: {
      list: {
        url: '/api/sp/materials'
      },
      get: {
        url: '/api/sp/material'
      },
    },
    materialin: {
      list: {
        url: '/api/sp/material/ins'
      },
    },
    materialout: {
      list: {
        url: '/api/sp/material/outs'
      },
    },
    materialinout: {
      list: {
        url: '/api/sp/material/inouthis'
      },
    },
    conducthis: {
      list: {
        url: '/api/sp/material/conducthis'
      },
    },
    conduct: {
      list: {
        url: '/api/sp/material/conducts'
      },
      get: {
        url: '/api/sp/material/conduct'
      },
    },
  },
  sys: {
    holiday: {
      list: {
        url: "/api/sys/holidays"
      },
    },
    appr: {
      type: {
        list: {
          url: "/api/sys/appr/types"
        },
        get: {
          url: "/api/sys/appr/type/{0}"
        }
      },
      line: {
        last: {
          url: "/api/sys/appr/lines/last"
        },
        list: {
          url: "/api/sys/appr/lines"
        },
      },
      list: {
        todo: {
          url: "/api/sys/appr/list/todo"
        },
        mycnt: {
          url: "/api/sys/appr/list/mycnt"
        },
      },
      info: {
        get: {
          url: "/api/sys/appr/info"
        },
      },
    },
    favorite: {
      list: {
        url: "/api/sys/favorites"
      },
    },
    log: {
      login: {
        list: {
          url: '/api/sys/log/logins'
        }
      },
      batch: {
        list: {
          url: '/api/sys/log/batches'
        }
      },
      menulog: {
        list: {
          url: "/api/sys/log/menulogs"
        },
      }
    },
    menu: {
      list: {
        url: "/api/sys/men/menus"
      },
      dashboard: {
        url: "/api/sys/men/menu/dashboards"
      },
      get: {
        url: "/api/sys/men/menu/{0}"
      }
    },
    manual: {
      get: {
        url: "/api/sys/men/manual/{0}"
      }
    },
    userMenu: {
      list: {
        url: "/api/sys/auth/user/usermenus"
      },
      get: {
        url: "/api/sys/auth/user/usermenus/"
      },
      favorites: {
        url: "/api/sys/auth/user/favorite/menu/"
      }
    },
    code: {
      domain: {
        list: {
          url: "/api/sys/code/domains"
        }
      },
      group: {
        list: {
          url: "/api/sys/code/grps/{0}"
        }
      },
      usegroup: {
        list: {
          url: "/api/sys/code/usegrps"
        }
      },
      mst: {
        list: {
          url: "/api/sys/code/msts/{0}",
          attr: {
            url: "/api/sys/code/msts/{0}/{1}"
          },
          multi: {
            url: "/api/sys/code/msts/multi",
          }
        },
      }
    },
    stepper: {
      grp: {
        list: {
          url: "/api/sys/stepper/grps"
        }
      },
      mst: {
        list: {
          url: "/api/sys/stepper/msts/{0}"
        },
        get: {
          url: "/api/sys/stepper/mst/{0}/{1}"
        },
      }
    },
    sequence: {
      list: {
        url: "/api/sys/sequences"
      },
      get: {
        url: "/api/sys/sequence/{0}"
      },
      check: {
        url: "/api/sys/sequence/check/{0}"
      }
    },
    attach: {
      list: {
        url: "/api/sys/attachsettings"
      },
      get: {
        url: "/api/sys/attachsetting/{0}"
      },
      check: {
        url: "/api/sys/attachsetting/check/{0}"
      }
    },
    excelUpload: {
      list: {
        url: "/api/sys/excelUploadSettings"
      },
      get: {
        url: "/api/sys/excelUploadSetting/{0}"
      },
      check: {
        url: "/api/sys/excelUploadSetting/check/{0}"
      },
      template: {
        url: "/api/sys/excelUpload/template"
      },
    },
    batch: {
      list: {
        url: "/api/sys/batchsettings"
      },
      get: {
        url: "/api/sys/batchsetting/{0}"
      },
      check: {
        url: "/api/sys/batchsetting/check/{0}"
      }
    },
    env: {
      list: {
        url: "/api/sys/envs"
      },
      get: {
        url: "/api/sys/env/{0}"
      },
    },
    notice: {
      list: {
        url: "/api/sys/notices"
      },
      main: {
        url: "/api/sys/main/notices"
      },
      get: {
        url: "/api/sys/notice/{0}"
      },
    },
    defect: {
      list: {
        url: "/api/sys/defects"
      },
      get: {
        url: "/api/sys/defect"
      },
    },
    lbl: {
      mst: {
        list: {
          url: "/api/sys/label/msts"
        }
      },
      dtl: {
        list: {
          url: "/api/sys/label/dtls/{0}"
        },
        get: {
          url: "/api/sys/label/dtl/{0}"
        },
      }
    },
    msg: {
      mst: {
        list: {
          url: "/api/sys/msg/msts"
        }
      },
      dtl: {
        list: {
          url: "/api/sys/msg/dtls/{0}"
        },
        get: {
          url: "/api/sys/msg/dtl/{0}"
        },
      }
    },
    auth: {
      grp: {
        list: {
          url: "/api/sys/auth/grps"
        },
        get: {
          url: "/api/sys/auth/grp/{0}"
        }
      },
      menu: {
        list: {
          url: "/api/sys/auth/menus"
        },
      },
      dept: {
        list: {
          url: "/api/sys/auth/depts"
        },
      },
      user: {
        list: {
          url: "/api/sys/auth/users"
        },
      },
      deptauth: {
        list: {
          url: "/api/sys/auth/deptauths"
        },
      },
      userauth: {
        list: {
          url: "/api/sys/auth/userauths"
        },
      },
      plant: {
        dept: {
          list: {
            url: "/api/sys/auth/plant/depts"
          },
        },
        user: {
          list: {
            url: "/api/sys/auth/plant/users"
          },
        },
      },
    },
  },
  sop: {
    swp: {
      workChekItem: {
        list: {
          url: "/api/sop/swp/checkItems"
        },
      },
      gas: {
        list: {
          url: "/api/sop/swp/gases"
        },
      },
      map: {
        list: {
          url: "/api/sop/swp/maps"
        },
        get: {
          url: "/api/sop/swp/map/{0}"
        },
        recent: {
          url: "/api/sop/swp/map/recent"
        },
      },
      workPermit: {
        list: {
          url: "/api/sop/swp/workPermits"
        },
        get: {
          url: "/api/sop/swp/workPermit/{0}"
        },
        recent: {
          url: "/api/sop/swp/workPermit/recent"
        },
        print: {
          url: "/api/sop/swp/workPermit/print/{0}"
        },
        state: {
          url: "/api/sop/swp/workPermitState"
        },
      },
    },
    min: {
      equipment: {
        annual: {
          list: {
            url: "/api/sop/min/equipment/annual"
          },
          get: {
            url: "/api/sop/min/equipment/annual/{0}/{1}"
          },
        },
        plan: {
          daily: {
            list: {
              url: "/api/sop/min/equipment/daily/plans"
            },
            get: {
              url: "/api/sop/min/equipment/daily/plan/{0}"
            },
            improve: {
              url: "/api/sop/min/equipment/daily/plan/improve/{0}/{1}"
            }
          },
          list: {
            url: "/api/sop/min/equipment/plans"
          },
          get: {
            url: "/api/sop/min/equipment/plan"
          }
        },
        result : {
          daily: {
            list: {
              url: "/api/sop/min/equipment/daily/results"
            },
            get: {
              url: "/api/sop/min/equipment/daily/result"
            },
            newItem: {
              url: "/api/sop/min/equipment/daily/new/result"
            },
            utilityItem: {
              url: "/api/sop/min/equipment/daily/utility/result/{0}"
            }
          },
          list: {
            url: "/api/sop/min/equipment/results"
          },
          improve: {
            url: "/api/sop/min/equipment/result/improves"
          },
          item: {
            get: {
              url: "/api/sop/min/equipment/result/items/{0}"
            }
          },
          lunar: {
            url: "/api/sop/min/equipment/lunar/{0}"
          }
        },
        checkCycle: {
          list: {
            url: "/api/sop/min/equipment/checkcycle"
          },
          print: {
            url: "/api/sop/min/equipment/checkcycle/print"
          }
        },
        maintain: {
          list: {
            url: "/api/sop/min/equipment/maintain/maintains"
          },
          getYear: {
            url: "/api/sop/min/equipment/maintain/maintain/year"
          },
          print: {
            url: "/api/sop/min/equipment/maintain/maintain/print"
          },
          print2: {
            url: "/api/sop/min/equipment/maintain/maintain/print2"
          }
        },
        improve: {
          get: {
            url: "/api/sop/min/equipment/improve"
          }
        }
      },
      maintenance: {
        plan: {
          list: {
            url: "/api/sop/min/maintenance/plans"
          },
          get: {
            url: "/api/sop/min/maintenance/plan/{0}"
          }
        },
        result : {
          list: {
            url: "/api/sop/min/maintenance/results"
          },
        },
      },
    },
    ibm: {
      improve: {
        others: {
          url: "/api/sop/ibm/improvement/others"
        },
        list: {
          url: "/api/sop/ibm/improvements"
        },
        get: {
          url: "/api/sop/ibm/improvement/{0}"
        },
        supple: {
          list: {
            url: "/api/sop/ibm/supple/improvements"
          },
        },
        status: {
          list: {
            url: "/api/sop/ibm/improvement/status"
          },
          task: {
            url: "/api/sop/ibm/improvement/task/status"
          },
          requestDept: {
            url: "/api/sop/ibm/improvement/requestDept/status"
          },
        },
        print: {
          url: "/api/sop/ibm/improvement/print"
        },
      },
      immediate: {
        get: {
          url: "/api/sop/ibm/immediate/{0}"
        },
      },
    },
    safetycheck: {
      item: {
        list: {
          url: "/api/sop/min/safetycheck/items"
        }
      },
      plan: {
        list: {
          url: "/api/sop/min/safetycheck/plans"
        },
        get: {
          url: "/api/sop/min/safetycheck/plan/{0}"
        }
      },
      result: {
        newItem: {
          url: "/api/sop/min/safetycheck/result/new/{0}"
        }
      },
      improve: {
        get: {
          url: "/api/sop/min/safetycheck/improve/{0}/{1}"
        }
      }
    },
  },
  mdm: {
    tool: {
      list: {
        url: "/api/mdm/tools"
      },
      get: {
        url: "/api/mdm/tool"
      },
    },
    costcenter: {
      list: {
        url: "/api/mdm/cost/centers"
      },
      get: {
        url: "/api/mdm/cost/center"
      },
    },
    deptmanager: {
      list: {
        url: "/api/mdm/dept/managers"
      },
    },
    cim: {
      vendor: {
        list: {
          url: "/api/mdm/cim/vendors"
        },
        get: {
          url: "/api/mdm/cim/vendor/{0}"
        },
        check: {
          url: "/api/mdm/cim/vendor/check/{0}"
        },
        user: {
          list: {
            url: "/api/mdm/cim/vendorusers"
          },
        },
        item: {
          list: {
            url: "/api/mdm/cim/vendoreval/items"
          },
        },
        type: {
          list: {
            url: "/api/mdm/cim/vendoreval/types"
          },
        },
        attach: {
          list: {
            url: "/api/mdm/cim/vendor/attacies"
          },
        },
        evaluation: {
          result: {
            list: {
              url: "/api/mdm/cim/vendoreval/results"
            },
            get: {
              url: "/api/mdm/cim/vendoreval/result/{0}"
            },
            item: {
              url: "/api/mdm/cim/vendoreval/resultitems"
            },
            user: {
              url: "/api/mdm/cim/vendoreval/resultusers"
            },
            history: {
              url: "/api/mdm/cim/vendoreval/resulthistory"
            },
          },
        },
        council: {
          list: {
            url: "/api/sop/vendor/councils"
          },
          get: {
            url: "/api/sop/vendor/council/{0}"
          }
        }
      },
    },
    sws: {
      mst: {
        list: {
          url: "/api/mdm/sws/swss"
        },
        get: {
          url: "/api/mdm/sws/sws"
        },
        copy: {
          url: "/api/mdm/sws/sws/copy"
        }
      },
      work: {
        list: {
          url: "/api/mdm/sws/works"
        },
        get: {
          url: "/api/mdm/sws/work"
        },
      },
      equip: {
        list: {
          url: "/api/mdm/sws/equips"
        },
      },
      cost: {
        list: {
          url: "/api/mdm/sws/costs"
        },
      },
      material: {
        list: {
          url: "/api/mdm/sws/materials"
        },
      },
      service: {
        list: {
          url: "/api/mdm/sws/services"
        },
      },
      tool: {
        list: {
          url: "/api/mdm/sws/tools"
        },
      },
      worker: {
        list: {
          url: "/api/mdm/sws/workers"
        },
      },
    },
    sop: {
      job: {
        list: {
          url: "/api/mdm/sop/sops"
        },
        get: {
          url: "/api/mdm/sop/sop/{0}"
        },
      },
      step: {
        url: "/api/mdm/sop/steps/{0}"
      },
      hazard: {
        url: "/api/mdm/sop/hazards/{0}/{1}"
      },
      jobstep: {
        url: "/api/mdm/sop/sopsteps"
      },
      jobhazard: {
        url: "/api/mdm/sop/sophazards"
      },
      jobhazardreason: {
        url: "/api/mdm/sop/sophazardreasons"
      },
      processHazard: {
        url: "/api/mdm/sop/sopprocesshazards"
      },
      processHazardReason: {
        url: "/api/mdm/sop/sopprocesshazardreasons"
      },
      sopHazard: {
        url: "/api/mdm/sop/processsophazard"
      },
      mm: {
        list: {
          url: "/api/mdm/sop/mms"
        },
      },
      chems: {
        list: {
          url: "/api/mdm/sop/chems"
        },
      },
    },
    user: {
      list: {
        url: "/api/mdm/hrm/users"
      },
      get: {
        url: "/api/mdm/hrm/user/{0}"
      },
      check: {
        loginid: {
          url: "/api/mdm/hrm/user/check/loginid"
        }
      },
    },
    dept: {
      list: {
        url: "/api/mdm/dim/depts"
      },
      get: {
        url: "/api/mdm/dim/dept/{0}"
      },
      check: {
        url: "/api/mdm/dim/dept/check/{0}"
      }
    },
    loc: {
      list: {
        url: "/api/mdm/loc/funclocs"
      },
      get: {
        url: "/api/mdm/loc/funcloc"
      },
    },
    bom: {
      list: {
        url: "/api/mdm/bom/boms"
      },
      get: {
        url: "/api/mdm/bom/bom"
      },
    },
    process: {
      list: {
        url: "/api/mdm/pim/processes"
      },
      get: {
        url: "/api/mdm/pim/process/{0}"
      },
      nameget: {
        url: "/api/mdm/pim/processnames/{0}"
      },
      check: {
        url: "/api/mdm/pim/process/check/{0}"
      }
    },
    equipment: {
      qrUrl: {
        url: "/api/mdm/fim/equipment/qr"
      },
      list: {
        url: "/api/mdm/fim/equipments"
      },
      operation: {
        list: {
          url: "/api/mdm/fim/equipment/operation"
        }
      },
      get: {
        url: "/api/mdm/fim/equipment"
      },
      check: {
        url: "/api/mdm/fim/equipment/check/{0}"
      },
      spec: {
        list: {
          url: "/api/mdm/fim/equipment/specs"
        },
        mst: {
          list: {
            url: "/api/mdm/fim/equipment/spec/msts"
          }
        }
      },
      breakdown: {
        list: {
          url: "/api/mdm/fim/equipment/breakdown"
        },
      },
      material: {
        list: {
          url: "/api/mdm/fim/equipment/materials"
        },
      },
      rank: {
        list: {
          url: "/api/mdm/fim/equipment/ranks"
        },
        get: {
          url: "/api/mdm/fim/equipment/rank"
        },
      },
      class: {
        list: {
          url: "/api/mdm/equipment/classess"
        },
        get: {
          url: "/api/mdm/equipment/class/{0}/{1}"
        },
        check: {
          url: "/api/mdm/equipment/class/check/{0}/{1}"
        },
        insItem: {
          list: {
            url: "/api/mdm/equipment/class/insitems/{0}/{1}"
          },
        },
        infoItem: {
          list: {
            url: "/api/mdm/equipment/class/infoitems"
          },
        },
        item: {
          daily: {
            list: {
              url: "/api/mdm/equipment/daily/items/{0}/{1}"
            },
          },
          equip: {
            list: {
              url: "/api/mdm/equipment/item/equipments"
            },
          },
          deterior: {
            list: {
              url: "/api/mdm/equipment/deterior/items/{0}/{1}"
            },
          },
          list: {
            url: "/api/mdm/equipment/class/items"
          }
        },
      },
      spareParts: {
        list: {
          url: '/api/mdm/equipment/spareparts',
        },
      },
      psm: {
        list: {
          url: '/api/mdm/fim/equipment/psm',
        },
      },
      license: {
        list: {
          url: '/api/mdm/fim/equipment/license',
        },
      },
    },
    senior: {
      list: {
        url: '/api/mdm/senior/seniors',
      },
      status: {
        list: {
          url: "/api/mdm/senior/statuses"
        },
        get: {
          url: "/api/mdm/senior/status/{0}"
        },
        rev: {
          url: "/api/mdm/senior/status/revs",
          get: {
            url: "/api/mdm/senior/status/rev/{0}"
          }
        }
      }
    }
  },
  pm: {
    mst: {
      list: {
        url: "/api/pm/msts"
      },
      get: {
        url: "/api/pm/mst"
      },
      work: {
        list: {
          url: "/api/pm/mst/works"
        },
        get: {
          url: "/api/pm/mst/work"
        },
      },
      equip: {
        list: {
          url: "/api/pm/mst/equips"
        },
      },
      cost: {
        list: {
          url: "/api/pm/mst/costs"
        },
      },
    },
    recomm: {
      check: {
        url: "/api/pm/recomm/check"
      },
      daily: {
        url: "/api/pm/recomm/daily",
      }
    },
    work: {
      list: {
        url: "/api/pm/works"
      },
    },
    activites: {
      list: {
        url: "/api/pm/activites"
      },
    },
  },
  sts: {
    rpa: {
      workorder: {
        url: "/api/sts/rpa/workorder"
      }
    },
    eck: {
      equipcheck: {
        url: "/api/sts/eck/equipcheck"
      }
    },
    pma: {
      pmdept: {
        url: "/api/sts/pma/pmdept"
      },
      pmyear: {
        url: "/api/sts/pma/pmyear"
      },
      pmmonth: {
        url: "/api/sts/pma/pmmonth"
      },
    },
    wha: {
      checkstatus: {
        url: "/api/sts/wha/checkstatus"
      },
      repaircost: {
        url: "/api/sts/wha/repaircost"
      },
      breaktime: {
        url: "/api/sts/wha/breaktime"
      },
      mttrmtbf: {
        url: "/api/sts/wha/mttrmtbf",
        graph: {
          url: "/api/sts/wha/mttrmtbf/graph"
        }
      },
    },
    bka: {
      locationtype: {
        url: "/api/sts/bka/locationtype"
      },
      dept: {
        url: "/api/sts/bka/dept"
      },
      equiptype: {
        url: "/api/sts/bka/equiptype"
      },
      breaktype: {
        url: "/api/sts/bka/breaktype"
      },
      equip: {
        url: "/api/sts/bka/equip"
      }
    }
  },
  main: {
    wo: {
      url: "/api/main/wo"
    },
    pm: {
      url: "/api/main/pm"
    }
  },
  kpi: {
    mst: {
      list: {
        url: "/api/kpi/msts"
      },
      detail: {
        list: {
          url: "/api/kpi/mst/details"
        }
      }
    },
    target: {
      list: {
        url: "/api/kpi/targets"
      },
      get: {
        url: "/api/kpi/target/{0}"
      },
      plan: {
        get: {
          url: "/api/kpi/target/plan"
        },
      },
      result: {
        list: {
          url: "/api/kpi/target/results"
        },
        get: {
          url: "/api/kpi/target/result/{0}"
        },
      }
    },
    strengthBreakRate: {
      url: "/api/kpi/strengthBreakRate"
    },
    frequencyBreakRate: {
      url: "/api/kpi/frequencyBreakRate"
    },
    operationRate: {
      url: "/api/kpi/operationrate"
    },
    pmRate: {
      url: "/api/kpi/pmRate"
    },
  }
};

export default selectConfig;
