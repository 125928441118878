<!--
  목적 : 설비별 BOM 컴포넌트
  작성자 : ldy
  Detail :
  *
  examples:
  *
  -->
<template>
  <div class="fix-height">
    <q-input
      bottom-slots
      readonly :dense="dense"
      :label="label"
      filled
      ref="customEquipBom"
      class="customEquipBom"
      stack-label
      :placeholder="label"
      :disable="disabled || !editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="textEquipmentBomName"
      >
      <template v-slot:label>
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>

      <template v-slot:append>
        <slot name="custom-btn-icon"></slot>
        <q-icon v-if="editable&&searchFlag" name="search" @click="click" class="cursor-pointer"/>
        <q-icon v-if="editable&&closeFlag" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-input>

    <q-dialog
      ref="equipBomDialog"
      persistent
      v-model="isOpen">
      <q-card class="dept-card">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-7 text-white">
          <div class="text-h6">기능위치</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectEquipBom"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline color="primary" text-color="white">
                {{equipmentBomName}}
              </q-chip>
              <q-input ref="filter" dense v-model="filter" label="Filter">
                <template v-slot:append>
                  <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
                </template>
              </q-input>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="dept-content p-2">
          <q-tree
            ref="customTreeEquipBom"
            :nodes="equipBomTree"
            node-key="equipmentBomCd"
            label-key="equipmentBomName"
            children-key="children"
            no-nodes-label="기능위치 정보가 없습니다."
            no-results-label="필터링된 데이터가 없습니다."
            :filter="filter"
            :default-expand-all="defaultExpandAll"
            selected-color="primary"
            :selected.sync="selected"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
import arrayToTree from 'array-to-tree';
export default {
  name: 'c-equip-bom',
  mixins: [mixinCommon],
  props: {
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'edit',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    // 라벨 텍스트
    label: {
      type: String,
      default: '설비별 BOM', // 부서
    },
    plantCd: {
      type: String,
    },
    equipmentCd: {
      type: String,
    },
    // 사용 여부
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    defaultExpandAll: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: String,
      default: 'single',
    },
    searchFlag: {
      type: Boolean,
      default: true,
    },
    closeFlag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      equipmentBomCd: '',
      equipmentBomName: '',
      textEquipmentBomName: '',
      placeholder: '선택',
      roleAccess: false,
      /** kdh */
      isOpen: false,
      filter: '',
      selected: null,
      equipBomTree: [],
    };
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.checkDefault();
    },
    plantCd() {
      this.checkDefault();
    },
    selected() {
      let data = this.$refs['customTreeEquipBom'].getNodeByKey(this.selected);

      this.equipmentBomCd = this.selected;
      if (data) {
        this.equipmentBomName = data.equipmentBomName;
      } else {
        this.equipmentBomName = '';
      }
    },
    searchFlag() {
      if (this.searchFlag === false) {
        this.$refs['customEquipBom'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      }
    },
    disabled() {
      if (this.disabled) {
        this.$refs['customEquipBom'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      } else {
        this.$refs['customEquipBom'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
        this.$refs['customEquipBom'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.click);
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customEquipBom'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.click);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customEquipBom'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
    }
  },
  methods: {
    init() {
      this.placeholder = this.type === 'search' ? 
        '전체' : // 전체
        '선택'; // 선택
      this.getList();
      if (this.searchFlag === false) {
        this.$refs['customEquipBom'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      }
    },
    getList() {
      this.$http.url = selectConfig.mdm.bom.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd,
        equipmentCd: this.equipmentCd,
      };
      this.$http.request((_result) => {
        this.funcLocs = _result.data;
        this.equipBomTree = this.convertTree(_result.data);
      },);
    },
    checkDefault() {
      this.$http.url = selectConfig.mdm.bom.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd,
        equipmentCd: this.equipmentCd,
      };
      this.$http.request((_result) => {
        this.funcLocs = _result.data;
        this.equipBomTree = this.convertTree(_result.data);
        
        let data = null;
        if (this.value) {
          data = this.$_.find(this.funcLocs, { equipmentBomCd: this.value });
          if (!data) {
            this.equipmentBomCd = null;
            this.equipmentBomName = null;
          } else {
            this.equipmentBomCd = data.equipmentBomCd;
            this.equipmentBomName = data.equipmentBomName;
          }
        } else {
          this.equipmentBomCd = null;
          this.equipmentBomName = null;
        }
        this.textEquipmentBomName = this.equipmentBomName;
        this.$emit('input', this.equipmentBomCd);
        // this.$emit('setequipmentBomName', this.equipmentBomName);
        this.$emit('datachange', this.equipmentBomCd);
      },);
    },
    /**
     * 메뉴 트리용 기초 정보를 Tree 메뉴로 변환
     */
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upEquipmentBomCd',
        customID: 'equipmentBomCd',
      });
      return tree;
    },
    click() {
      if (this.editable && !this.disabled) {
        this.isOpen = true;
      }
    },
    resetFilter () {
      this.filter = ''
      this.$refs.filter.focus()
    },
    reset() {
      this.textEquipmentBomName = '';
      this.equipmentBomCd = '';
      this.equipmentBomName = '';
      this.$emit('input', this.equipmentBomCd);
      // this.$emit('setequipmentBomName', this.equipmentBomName);
      this.$emit('datachange', this.equipmentBomCd);
    },
    selectEquipBom() {
      if (this.equipmentBomName) {
        this.textEquipmentBomName = this.equipmentBomName;
        this.$emit('input', this.equipmentBomCd);
        // this.$emit('setequipmentBomName', this.equipmentBomName);
        this.$emit('datachange', this.equipmentBomCd);
      }
      this.$refs['equipBomDialog'].hide();
    }
  },
};
</script>
<style lang="sass">
.dept-card
  width: 100%
  max-width: 450px !important
  height: 100%
  max-height: 500px !important
  overflow-y: hidden !important
.dept-content
  height: 100%
  max-height: 370px !important
  overflow-y: auto
.dept-card
  .bg-dialogheader
    height: 40px
    .q-btn
      .q-btn__wrapper
        padding: 0px !important
  .text-h6
    font-size: 1.2em
    line-height: 1.2em
    font-weight: 700
.customEquipBom
  .q-field__native
    padding-left:3px
    font-size: 0.95em !important
    cursor: pointer !important
  .q-field__label
    margin-left: 5px
  .q-field__native::placeholder
    font-size: 1em !important
  .material-icons
    font-size: 0.8rem
  .deptCloseIcon
    display: none
.customEquipBom:hover
  .deptCloseIcon
    display: block
.customEquipBom.q-field--dense .q-field__control, .customEquipBom.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.customEquipBom.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.customEquipBom.q-field--filled.q-field--readonly .q-field__control:before
  opacity: 0
</style>